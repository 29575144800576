import createScrollableElements, { centerAlignScrollableElement } from 'utils/scrollable';
import trackLink from '@99designs/common/utils/platform/trackLink';

(function ($) {
    const scrollables = document.querySelectorAll(
        '[data-scrollable="designer-search-result-gallery"]'
    );

    Array.from(scrollables).forEach((scrollable) => {
        const inner = scrollable.querySelector('[data-scrollable-inner]');
        const gallery = scrollable.querySelector('[data-designer-search-result-gallery]');

        inner.setAttribute('data-size', gallery.offsetWidth);
    });

    createScrollableElements('[data-scrollable="designer-search-result-gallery"]');
    centerAlignScrollableElement('[data-scrollable="designer-search-result-gallery"]');

    $('[data-designer-search-result-cta]').each(function () {
        trackLink(this, 'Clicked CTA on Blog Post', {
            type: 'designer-search-plugin',
            designer_id: this.getAttribute('data-designer-id'),
            designer_name: this.getAttribute('data-designer-name'),
        });
    });

    $('[data-designer-search-results-more-cta]').each(function () {
        trackLink(this, 'Clicked CTA on Blog Post', {
            type: 'designer-search-plugin',
        });
    });

    $('[data-star-rating]').each(function () {
        trackLink(this, 'Clicked Rating Review Count on Blog Designer Search Plugin', {
            designer_id: this.getAttribute('data-designer-id'),
            designer_name: this.getAttribute('data-designer-name'),
        });
    });
})(jQuery);
